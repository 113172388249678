<div id="app">
  <div class="absolute top-0 right-0 z-x">
    <img src="svgs/shape.svg" alt="">
  </div>
  <app-navbar></app-navbar>
  <div class="container mx-auto pb-2 relative relative">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="fixed inset-x-0 bottom-0" z-index="100" *ngIf="showBanner">
  <div class="bg-yellow-500">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex p-2 rounded-lg bg-orange-500">
            <svg class="h-6 w-6 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
            </svg>
          </span>
          <p class="ml-3 font-medium text-black truncate">
            <span class="md:hidden">
              We will continue to operate during all Covid Alert Levels
            </span>
            <span class="hidden md:inline">
              DebtorInfo will continue to operate normally during all Covid Alert Levels.
            </span>
          </p>
        </div>
        <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
          <button type="button" class="-mr-1 flex p-2 rounded-md hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2" (click)="showBanner = false">
            <svg class="h-6 w-6 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-cta></app-cta>
<app-footer></app-footer>
