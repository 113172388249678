import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  date: Date;

  constructor() { }

  ngOnInit(): void {
    this.date = new Date();
  }

}
