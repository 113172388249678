import { Component } from '@angular/core';

@Component({
  selector: 'app-contacted',
  templateUrl: './contacted.component.html'
})
export class ContactedComponent {

  constructor() { }

}
