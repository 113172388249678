<div class="container px-6 md:pb-24 mx-auto relative z-30">
    <div class="block text-center md:mb-16 mb-8 py-16 border-b border-grey-200">
        <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl">FAQ</h1>
        <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">Frequently Asked Questions</p>
    </div>
</div>
<div class="container mx-auto max-w-4xl px-6 relative z-30 pb-16 md:mb-16 mb-0">
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. How do my debtors get onto Debtorinfo?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Debtorinfo will load the debts automatically from your accounting software if connected, or you can add
            debts manually.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Once the debts are loaded, do I have any control over what
            reminders go out to my debtors?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Yes you are in full control, some people need reminders and prompts, others, for various reasons will
            never be sent a reminder and you control that.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. How many options of reminders are there?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. There are six letters or emails, the first two are shown as coming from you, the other four are shown as
            coming from Debtorinfo.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. What is the difference between the letters or emails?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. The first two are friendly reminders from you, the third one is a friendly reminder from Debtorinfo, the
            fourth is from Debtorinfo and reminds your debtor that they have an A1 Credit Rating that they are about to
            lose, the fifth is from Debtorinfo and confirms they have lost their A1 Credit Rating but can get their A1
            Credit Rating back if they pay your account, and sixth states they are on a Credit Stop until they pay your
            account. All these letters are sent from your computer, when you say so.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Does the debtor really lose their A1 credit rating.</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A.  Yes, a person with unpaid accounts will not get credit and we list your debtor with unpaid accounts on ours and other databases used by banks and finance companies.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Why would your system be better than our own office staff?
        </h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. We are in the business of generating Credit Ratings which affect a debtor’s reputation and ability to borrow money or get credit. There are real repercussions if a debtor ignores our letters asking that your account be paid.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. What happens if the debtor ignores your letters?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Firstly the debtor loses their A1 Credit Rating, secondly you can ask us to list your debtor on the databases of bad debtors that banks use, there is  a small charge for us to do this, you can ask us to contact the debtor by phone to negotiate settlement again for a small extra charge, then if you want to take your debtor to court, for an additional small  extra charge we can help you prepare your Disputes Tribunal claim or your Notice of Proceedings documents for the court.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. What happens if a debt is disputed?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. You can request a letter or email be sent to the debtor with Disputes Tribunal forms attached at the push of a button, it is all automatic.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. I only have a very limited competence level with computing and I struggle to find my way around software programs, will I find Debtorinfo easy to use.</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Yes, our users have no problems it is exceptionally easy to use, and we are just a phone call away if you need help.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. What happens if the debtor ignores your letters?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Firstly the debtor loses their A1 Credit Rating, secondly you can ask us to list your debtor on the databases of bad debtors that banks use, there is  a small charge for us to do this, you can ask us to contact the debtor by phone to negotiate settlement again for a small extra charge, then if you want to take your debtor to court, for an additional small  extra charge we can help you prepare your Disputes Tribunal claim or your Notice of Proceedings documents for the court.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Do I get prompts and reminders?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Yes
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Can I email or print off letters to send to my debtors.</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Yes, you have the option.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Can I add collection charges?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. On your Debtorinfo web site you will have a complete set of company documentation including terms of trade, credit application forms, deferred payment agreements etc, all personalised in your business / practices name, and if these forms are used when you grant credit they will give you the right to add late payment fees of 20% which you get to keep.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Do I ever get charged commission or loading fees?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. No never.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. How much does it cost?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Doctors, Contractors, Tradesmen, Vets, Dentists and other professions pay just $49.95 plus GST a month.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Why is Debtorinfo so cheap?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Compared to loading a debt at a debt collector it is unbelievably cheap, but when compared with what online accounting systems cost and what they can do, our pricing is right where it should be.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Do you connect to accounting and other business software systems?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Yes, we connect to Xero SAM, Orion, Synergy and lots more, and we will most likely be able to connect to your management system software if asked.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. How long have you been doing this?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. We started in Dunedin when the internet was in its infancy, in 1996.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. Do you partner with trade associations?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. Yes, and our oldest partnership is with MTA and that goes back to 2002.
        </p>
    </div>
    <div class="pb-4">
        <h1 class="font-semibold text-grey-800 mb-2">Q. How old is your software?</h1> ​
        <p class="text-lg text-grey-700 leading-wide">
            A. The Debtorinfo web site was completely rebuilt during 2019 and was launched in March 2020 and incorporates the latest in software development and security.
        </p>
    </div>
</div>
