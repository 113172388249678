<div class="container px-6 mx-auto md:mb-16 mb-8 text-center md:pt-16 pt-8">
    <div class="container px-6 mx-auto md:pt-20">
        <div class="text-center pb-8 pb-4">
            <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl">What our subscribers say</h1>
        </div>
        <div class="border-t border-grey-300 md:pt-8 pt-8"></div>
        <div class="md:pb-16 pb-0 max-w-4xl mx-auto">
            <div class="flex justify-between flex-wrap items-center md:-mx-8 -mx-6">
                <div class="lg:flex-1 w-full px-8 lg:border-r border-r-0 border-grey-300 pb-10 md:block hidden">
                    <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
                        "Debtorinfo is incredibly successful in prompting payments, we really like it."
                    </div>
                    <div>
                        <h1 class="font-semibold font-custom">A  Veterinary Centre</h1>
                    </div>
                </div>
                <div class="lg:flex-1 w-full px-8">
                    <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
                        "We love the Debtorinfo system it works really well for us."
                    </div>
                    <div>
                        <h1 class="font-semibold font-custom">A  Medical Centre</h1>
                    </div>
                </div>
            </div>
            <div class="flex justify-between flex-wrap items-center md:-mx-8 -mx-6">
                <div class="lg:flex-1 w-full px-8 lg:border-r border-r-0 border-grey-300 pb-10 md:block hidden">
                    <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
                        "We have used it since it started, it’s brilliant."
                    </div>
                    <div>
                        <h1 class="font-semibold font-custom">A  Motor Repair Workshop</h1>
                    </div>
                </div>
                <div class="lg:flex-1 w-full px-8">
                    <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
                        "It is nice to have Debtorinfo there if we need it or need advice, it helps us in many ways."
                    </div>
                    <div>
                        <h1 class="font-semibold font-custom">A Plumber</h1>
                    </div>
                </div>
            </div>
            <div class="flex justify-between flex-wrap items-center md:-mx-8 -mx-6">
                <div class="lg:flex-1 w-full px-8 lg:border-r border-r-0 border-grey-300 pb-10 md:block hidden">
                    <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
                        "When we need to collect a debt, we use Debtorinfo and it works brilliantly."
                    </div>
                    <div>
                        <h1 class="font-semibold font-custom">An  Importer</h1>
                    </div>
                </div>
                <div class="lg:flex-1 w-full px-8">
                    <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
                        "We have only had to use Debtorinfo once and it worked."
                    </div>
                    <div>
                        <h1 class="font-semibold font-custom">A Otago producer</h1>
                    </div>
                </div>
            </div>
            <div class="flex justify-between flex-wrap items-center md:-mx-8 -mx-6">
                <div class="lg:flex-1 w-full px-8 lg:border-r border-r-0 border-grey-300 pb-10 md:block hidden">
                    <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
                        "We use Debtorinfo and it works for us."
                    </div>
                    <div>
                        <h1 class="font-semibold font-custom">A Building Contractor</h1>
                    </div>
                </div>
                <div class="lg:flex-1 w-full px-8">
                    <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
                        "We subscribe to Debtorinfo and always get a positive result."
                    </div>
                    <div>
                        <h1 class="font-semibold font-custom">A Plumber</h1>
                    </div>
                </div>
            </div>
        </div>
        <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">
            While some debtors will delay payment if there are no repercussions, most debtors want to protect their
            credit rating and continue to get credit when they ask for it, so pay their account when they are advised
            that their credit rating is about to be down graded.
        </p>
    </div>
</div>
