<div class="flex justify-between items-center pt-6 pb-4 my-2 lg:mx-12 lg:px-0 px-6">
  <div class="flex items-center pr-6 ">
    <a href="/">
      <img class="logo" src="/assets/logo3.svg" alt="DebtorInfo" style="height: 50px; width: auto;">
    </a>
  </div>
  <div class="text-lg font-semibold lg:flex hidden flex-1 justify-start -mx-4">
    <a routerLink="/why-it-works"
      class="mx-4 items-center px-1 py-2 rounded text-grey-600 no-underline hover:text-grey-800 hover:bg-black-20">
      Why it works
    </a>
    <a routerLink="/services"
      class="mx-4 items-center px-1 py-2 rounded text-grey-600 no-underline hover:text-grey-800 hover:bg-black-20">
      Services
    </a>
    <a routerLink="/pricing"
      class="mx-4 items-center px-1 py-2 rounded text-grey-600 no-underline hover:text-grey-800 hover:bg-black-20">
      Pricing
    </a>
    <a routerLink="/accolades"
      class="mx-4 items-center px-1 py-2 rounded text-grey-600 no-underline hover:text-grey-800 hover:bg-black-20">
      Accolades
    </a>
    <a routerLink="/contact"
      class="mx-4 items-center px-1 py-2 rounded text-grey-600 no-underline hover:text-grey-800 hover:bg-black-20">
      Contact
    </a>
    <a routerLink="/faq"
      class="mx-4 items-center px-1 py-2 rounded text-grey-600 no-underline hover:text-grey-800 hover:bg-black-20">
      FAQ
    </a>
  </div>
  <div class="lg:flex hidden">
    <div class="flex-1 justify-end flex">
      <a href="https://app.debtorinfo.com.au/#/auth/signin"
        class="mx-4 items-center py-2 rounded text-lg font-semibold text-grey-600 no-underline hover:text-grey-800 hover:bg-black-20">
        Sign In
      </a>
      <a [routerLink]="['/contact']" class="btn primary">Get In Touch</a>
    </div>
  </div>
  <div class="lg:hidden flex">
    <a (click)="showNav = !showNav" class="cursor-pointer ml-4 whitespace-no-wrap inline-flex items-center py-2 rounded text-grey-500">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="#4A5568" stroke="#4A5568">
          <line data-color="color-2" fill="none" stroke-miterlimit="10" x1="1" y1="12" x2="23" y2="12"></line>
          <line fill="none" stroke="#4A5568" stroke-miterlimit="10" x1="1" y1="5" x2="23" y2="5"></line>
          <line fill="none" stroke="#4A5568" stroke-miterlimit="10" x1="1" y1="19" x2="23" y2="19"></line>
        </g>
      </svg>
    </a>
  </div>
</div>
<ng-container *ngIf="showNav">
  <div class="flex justify-center text-center py-8 bg-white shadow-lg absolute w-full z-50">
    <div>
      <a (click)="navigate('why-it-works')"
        class="text-lg font-medium pb-4 text-grey-600 hover:text-grey-900 block">
          Why it works
      </a>
      <a (click)="navigate('services')"
        class="text-lg font-medium pb-4 text-grey-600 hover:text-grey-900 block">
          Services
      </a>
      <a (click)="navigate('pricing')"
        class="text-lg font-medium pb-4 text-grey-600 hover:text-grey-900 block">
          Pricing
      </a>
      <a (click)="navigate('accolades')"
        class="text-lg font-medium pb-4 text-grey-600 hover:text-grey-900 block">
          Accolades
      </a>
      <a (click)="navigate('contact')"
        class="text-lg font-medium pb-4 text-grey-600 hover:text-grey-900 block">
          Contact
      </a>
      <a (click)="navigate('faq')"
        class="text-lg font-medium pb-4 text-grey-600 hover:text-grey-900 block">
          FAQ
      </a>
      <div class="py-4">
        <a class="text-grey-800 w-56 block border-grey-400 border py-3 px-4 rounded font-medium text-sm mb-6" href="https://app.debtorinfo.com.au/#/auth/signin">
          Sign in
        </a>
        <a class="text-white w-56 btn primary md justify-center" (click)="navigate('contact')">Get Started</a>
      </div>
      <div class="text-grey-500 text-md leading-wide">Copyright © Debtorinfo {{ date.getFullYear() }}</div>
    </div>
  </div>
</ng-container>
