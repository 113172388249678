<div class="container mx-auto relative lg:px-0 px-6">
  <div class="py-4 lg:py-18">
    <div class="flex flex-wrap items-center">
      <div class="flex-1">
        <div class="lg:pr-8">
          <div class="max-w-md lg:max-w-s mx-auto">
            <h1 class="text-grey-700 font-semibold text-4xl mb-2">
              We Make Getting Paid Easy, Effective and Totally Affordable.
            </h1>
            <div class="w-full">
              <div class="py-10">
                <div class="flex items-center mb-4">
                  <img src="/assets/tick-dark.svg" alt="">
                  <span class="pl-4 md:text-ss text-ss font-light text-grey-600 leading-wide ">No Commission, no loading
                    fees, zero surprises.
                  </span>
                </div>
                <div class="flex items-center mb-4">
                  <img src="/assets/tick-dark.svg" alt="">
                  <span class="pl-4 md:text-ss text-ss font-light text-grey-600 leading-wide ">You get paid faster, and
                    your debtor is not offended.</span>
                </div>
                <div class="flex items-center mb-4">
                  <img src="/assets/tick-dark.svg" alt="">
                  <span class="pl-4 md:text-ss text-ss font-light text-grey-600 leading-wide ">We've been
                    revolutionizing debt collection for 24 years.</span>
                </div>
              </div>
            </div>
            <style>
              .cta-container {
                perspective: 1000;
                backface-visibility: hidden;
                background: #fff;
              }

              .cta {
                text-align: center;
                border: none;
                border-radius: 10%;
                background: #70D0DB;
                cursor: pointer;
                box-shadow: 0 0 0 0 rgba(#70D0DB, .5);
                animation: pulse 0.5s infinite;
              }

              .cta:hover {
                animation: none;
              }

              @keyframes pulse {
                0% {
                  transform: scale(0.97);
                }

                70% {
                  transform: scale(1);
                  box-shadow: 0 0 0 50px rgba(#70D0DB, 0);
                }

                100% {
                  transform: scale(0.97);
                  box-shadow: 0 0 0 0 rgba(#70D0DB, 0);
                }
              }
            </style>
            <div class="mt-4 flex w-full">
              <div class="cta-container inline-flex w-full">
                <a [routerLink]="['/contact']"
                  class="cta w-full inline-flex items-center justify-center px-5 py-3 text-2xl font-extrabold rounded-md text-white"
                  style="background: linear-gradient(270deg, #01ACC0 0%, #70D0DB 100%); border-radius: 6px;">
                  Contact us now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:w-1/2 w-full lg:flex hidden justify-center lg:pb-0 pb-16">
        <img src="/assets/main.svg" alt="">
      </div>
    </div>
  </div>
</div>

<div class="container max-w-4xl pt-4 mx-auto">
  <div class="flex flex-wrap">
    <div class="mx-auto">
      <div class="py-4 pr-6 text-2xl text-center font-semibold">
        We tick all the boxes
      </div>
      <div class="pb-6 h-full text-base text-grey-600 text-left">
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-2 pr-4">AU Owned</span>
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-2 pr-4">Nationwide Coverage</span>
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-2 pr-4">Multiple Compliance Solutions</span>
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-2 pr-4">Game-Changing Results</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-2 pr-4">Significant Cost Savings</span>
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-2 pr-4">Major Trade Association Partnerships</span>
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-2 pr-4">Over 25 Years In Business</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-gray-100">
  <div class="max-w-7xl mx-auto pt-2 pb-24 px-4 sm:px-6 lg:px-8">
    <p class="text-center text-lg font-semibold text-grey-700 tracking-wide">
      DebtorInfo is partnered with
    </p>
    <div class="text-center text-3xl font-extrabold">
      <span class="px-2" style="color: #22A8E0">
        MTA
      </span>
      <span class="px-2" style="color: #00548B">
        Master Electricians
      </span>
    </div>
    <p class="text-center text-lg font-semibold text-grey-700 tracking-wide">
      and other groups, to provide our services to their members.
    </p>
  </div>
</div>

<div class="container text-center mx-auto px-6 pb-24 hidden sm:block">
  <iframe width="852" height="480" class="mr-auto ml-auto" src="https://www.youtube.com/embed/17KrbgryVig"
    title="YouTube video player" frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
  </iframe>
</div>
<div class="container text-center mx-auto px-6 pb-24">
  <div class="pb-10 text-grey-600 text-md font-semibold">Ready to go features, right out of the box.</div>
  <div class="border-b border-grey-200"></div>
  <div class="flex flex-wrap items-center justify-center w-full pt-10">
    <div class="md:flex-1 w-1/2 flex justify-center">
      <div class="text-center">
        <img class="inline-flex mb-4" src="/assets/icons/docs.svg" alt="Documentation">
        <h1 class="font-medium text-grey-600 text-sm mb-2">Documentation</h1>
      </div>
    </div>
    <div class="md:flex-1 w-1/2 flex justify-center">
      <div class="text-center">
        <img class="inline-flex mb-4" src="/assets/icons/debt.svg" alt="Debt Collection">
        <h1 class="font-medium text-grey-600 text-sm mb-2">Debt Collection</h1>
      </div>
    </div>
    <div class="md:flex-1 w-1/2 flex justify-center">
      <div class="text-center">
        <img class="inline-flex mb-4" src="/assets/icons/legal.svg" alt="Legal">
        <h1 class="font-medium text-grey-600 text-sm mb-2">Legal</h1>
      </div>
    </div>
    <div class="md:flex-1 w-1/2 flex justify-center">
      <div class="text-center">
        <img class="inline-flex mb-4" src="/assets/icons/compliance.svg" alt="Compliance">
        <h1 class="font-medium text-grey-600 text-sm mb-2">Compliance</h1>
      </div>
    </div>
    <div class="md:flex-1 w-1/2 flex justify-center">
      <div class="text-center">
        <img class="inline-flex mb-4" src="/assets/icons/more.svg" alt="More Tools">
        <h1 class="font-medium text-grey-600 text-sm mb-2">More Tools </h1>
      </div>
    </div>
  </div>
</div>
<div class="container px-6 mx-auto mb-16 text-center">
  <div class="text-center md:pb-16 pb-4">
    <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl pb-2">What We Offer</h1>
    <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">
      Recovery of bad-debts through our verified debt collection system that's both easy to use and informative to your
      debtors. We've created a large database of bad debtors over the past 20 years. So we know hot to recover your
      accounts receivable, and we've built the perfect cashflow management system for you to do just that in a few
      simple clicks.
    </p>
  </div>
  <div class="flex flex-wrap text-grey-800 pb-16">
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/1.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Getting paid on time</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        It is no accident that some companies always get paid on time while others don’t. Our first service to
        our
        subscribers
        is to help them improve their cash flow; we have a very clever system that puts our subscribers on the
        top
        of their
        clients list to be paid.
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/2.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Health & Safety</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        We supply a personalised Health and Safety manual for offices, workshops, factories, shops, warehouses
        etc.
        which
        enables our subscribers and their staff to get started on the task of ensuring Health and Safety systems
        are
        put in
        place and monitored.
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/3.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Terms of Trade</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        Ever wondered why some companies are always secured creditors in a Receivership and perhaps you are not?
        The
        answer is
        that unsecured creditors neglect to get permission from the client or simply don’t register; this is a
        major
        focus of
        the service we provide our subscribers.
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/4.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Documentation</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        We often get asked to collect a debt from someone who paid some of the account and promised to pay the
        balance later,
        but didn’t. We supply you with a library of letters, documents and forms to cover every contingency you
        may
        need to
        address, including deferred payments
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/5.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Credit Rating</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        We designed a system twenty years ago that enables access to bad debtor information easily to stop them
        going from one
        business to another. In that time we have saved a whole range of business groups hundreds of thousands
        of
        dollars by
        eliminating credit risk.
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/6.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">A raft of other services </h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        Running a business is supposed to be fun and profitable but sadly for many it is not. We provide many
        services to bring
        your business into the 21st century. It is reassuring to know that you are using the same system and
        processes that are
        used by hundreds of other business.
      </p>
    </div>
  </div>
</div>
<div class="container pb-12 mx-auto">
  <div class="block text-center md:pb-16 pb-4">
    <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl mb-2">Fair Pricing</h1>
  </div>
  <div class="flex flex-wrap items-center">
    <div style="height: 40rem;" class="md:flex hidden md:w-38 w-full">
      <div class="max-w-md mx-auto pr-10">
        <div class="pb-6 text-2xl font-semibold">
          These are some of the Services available on the Debtorinfo web site
        </div>
        <!-- features -->
        <div class="pb-6 h-full text-base text-grey-600 text-left">
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Debt Management and Recovery Tools</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Integration with leading accounting systems</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Online - Quotes & Terms Acknowledgement</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Personalised Documentation, Forms and Letters specific to your Industry or
              Profession</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">AU Bad Debtors Register</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Debt Collection Solutions</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Contractors Terms of Trade</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">PPSR Registrations</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Construction Contracts Act</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Building Amendment Act</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Workers Lien</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Health and Safety Manual</span>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40rem;" class="md:w-30 w-full bg-grey-700 shadow-lg rounded-lg mt-5 md:mr-5">
      <div class="w-full text-white px-8 pt-8 pb-8 bg-grey-800 rounded-t-lg">
        <span class="tag">Pricing</span>
        <h1 class="text-2xl font-light">Platinum Service</h1>
        <div class=" ">
          <div class="text-white text-4xl font-semibold">$49.95 <span class="text-teal-200 text-2xl font-light">/
              month</span></div>
          <span class="text-teal-200 text-sm">excl. GST</span>
        </div>
      </div>
      <div class="px-8 py-12">
        <span class="font-medium text-teal-200 pb-4 block">Plan Comes with:</span>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Unlimited Collections</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Loading Fees</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Hidden Costs</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Surprises</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Unlimited Documentation <br>Access</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Unlimited access to the <br>AU Bad Debtors
            Register</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Discounts on Statutory <br>Demands and other
            services</span>
        </div>
      </div>
    </div>
    <div style="height: 40rem;" class="md:w-30 w-full bg-grey-700 shadow-lg rounded-lg mt-5">
      <div class="w-full text-white px-8 pt-8 pb-8 bg-grey-800 rounded-t-lg">
        <span class="tag">Pricing</span>
        <h1 class="text-2xl font-light">Gold Service</h1>
        <div class=" ">
          <div class="text-white text-4xl font-semibold">$20.00 <span class="text-teal-200 text-2xl font-light">/
              month</span></div>
          <span class="text-teal-200 text-sm">excl. GST</span>
        </div>
      </div>
      <div class="px-8 py-12">
        <span class="font-medium text-teal-200 pb-4 block">Plan Comes with:</span>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Unlimited Collections</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Loading Fees</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Hidden Costs</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Surprises</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Pay for access to <br>Documentation and Forms</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Pay for access to the <br>AU Bad Debtors Register</span>
        </div>
      </div>
    </div>
  </div>
</div>
<h1 class="text-grey-700 font-bold md:text-3xl text-2xl text-center">Special Offer for this month</h1>
<div class="w-full"
  style="background: linear-gradient(270deg, #01ACC0 0%, #70D0DB 100%); animation: pulse 0.5s infinite;border-radius: 6px;">
  <div class="w-full px-5 py-3 text-2xl font-extrabold text-white text-center">If you are a member of a Trade Group or
    Business Assn we will sign you up to a Platinum Subscription for the Gold
    Subscription Price.</div>
</div>