import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { EmailService } from '../services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {
  formErrorStrings: any = {
    name: {
      required: 'Name is required.',
      maxlength: 'Must be no more than 50 characters.'
    },
    email: {
      required: 'Email is required.',
      email: 'Must be a valid email address.'
    },
    message: {
      maxlength: 'Must be no more than 250 characters.'
    }
  };
  form: FormGroup;
  formErrors: any = {};
  loading: boolean = true;
  sent: boolean = false;
  unsubscribe: Subject<void> = new Subject<void>();
  sending: boolean = false;

  constructor(private emailService: EmailService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

	/**
	 * Builds the form.
	 */
  buildForm() {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(50)]],
      phone: ['', [Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.maxLength(250)]
    });
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.formErrors = this.validateForm(this.form);
    });
  }

	/**
	 * Checks form validation.
	 * @param formGroup FormGroup instance.
	 * @param force forces validation of pristine controls.
	 */
  validateForm(formGroup: FormGroup) {
    const errors = {};
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control.dirty && !control.valid) {
        const errorStrings = this.formErrorStrings[key];
        errors[key] = errorStrings[Object.keys(control.errors)[0]];
      }
    });
    return errors;
  }

	/**
	 * Sends the audit request.
	 */
  async requestContact() {
    this.loading = true;
    this.sending = true;
    const data = {
      email: this.form.value.email,
      firstName: this.form.value.firstName,
      phone: this.form.value.phone,
      userMessage: this.form.value.message
    };
    const contact = {
      email: data.email,
      firstName: data.firstName,
      phone: data.phone,
      contact: data
    };
    await this.emailService.sendContactRequestEmail(data).then(() => {
      this.sent = true;
      this.sending = false;
      // this.googleService.emitEvent('contact');
      this.router.navigateByUrl('/thanks'); // TODO: thank you page needed.
      // this.emailService.createContact(contact).then(() => {
      // })
    });
  }

	/**
	 * Sets up the component for another request.
	 */
  newRequest() {
    this.form.reset();
    this.sent = false;
  }
}
