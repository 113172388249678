<div class="container px-6 md:pb-24 mx-auto">
  <div class="block text-center md:mb-16 mb-8 md:py-16 py-8  border-b border-grey-200">
    <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl">About Us</h1>
    <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">Helping to manage cashflow since 1996</p>
  </div>
</div>
<div class="container mx-auto max-w-4xl px-6 pb-16">
  <div class="pb-4">
    <div class="text-grey-700 font-semibold md:text-2xl text-xl">How it all Started</div> ​
  </div>
  <p class="text-lg text-grey-700 leading-wide pb-4">
    In 1996 while delivering a seminar on credit management to a large trade group we were asked to develop a quick,
    legal,
    and inexpensive way for their members to improve their cashflow, collect their debts, keep late payment and
    interest
    charges, pay no commission, and share information about their customers who did not pay their bills with their
    colleagues in business.
  </p>
  <p class="text-lg text-grey-700 leading-wide pb-4">
    And that's what we did and it has worked well for 20 years.
  </p>
  <p class="text-lg text-grey-700 leading-wide pb-4">
    In that time we have built up a large and diverse customer base who have supplied the information that has
    enabled us to
    build a credible database of the payment habits and trends of individuals and businesses.
  </p>
  <p class="text-lg text-grey-700 leading-wide pb-4">
    Our clients pay us a small monthly subscription for unlimited access to this resource and a raft of others
    including
    business compliance documentation, health and safety documentation, and other documents, forms and information
    critical
    to running a successful business.
  </p>
</div>
