import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private _ownerEmail: string = environment.owner_email;

  constructor(private httpClient: HttpClient) { }

	/**
	 * Sends an email. Cannot be called by methods outside of this class, in order to enforce correct email formatting.
	 * @param emailContent the email content.
	 */
  private async _sendEmail(emailContent: any) {
    const url = environment.email_url;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    return this.httpClient
      .post(url, emailContent, { headers })
      .toPromise()
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

	/**
	 * Sends a contact request email to SellMoreTech.
	 * @param data email data.
	 */
  sendContactRequestEmail(data: any) {
    const body = {
      email: this._ownerEmail,
      body: {
        header: 'Contact Request',
        bodyText: `
        <h1>Contact Request</h1><br>
        <p>${data.email} has contacted you using the contact form.</p><br><br>
        <h3>Message</h3><br>
        <p>${data.userMessage}</p><br>
        <p>Email Address: ${data.email}</p>
      `
      },
      admin: false,
      companyName: '',
      useCompanyName: false
    };
    return this._sendEmail(body);
  }

	/**
	 * Sends a contact request email to SellMoreTech.
	 * @param data email data.
	 */
  // sendContactEmail(data: any) {
  //   const body = {
  //     email: this._ownerEmail,
  //     body: {
  //       header: 'Contact Request',
  //       bodyText: `
  //       <h1>Contact Request</h1><br>
  //       <p>${data.email} has contacted you using the contact form.</p><br><br>
  //       <h3>Message</h3><br>
  //       <p>${data.userMessage}</p><br>
  //       <p>Email Address: ${data.email}</p>
  //       <p>Phone Number: ${data.phone}</p>
  //     `
  //     },
  //     admin: false,
  //     companyName: '',
  //     useCompanyName: false
  //   };
  //   const body = {
  //     sendOwner: false,
  //     mailType: 'contact',
  //     ownerEmail: this._ownerEmail,
  //     name: data.name,
  //     message: data.userMessage,
  //     email: data.email,
  //     type: data.type,
  //     contact: data
  //   };
  //   return this._sendEmail(body);
  // }
}
