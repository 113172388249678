import { Component } from '@angular/core';

@Component({
  selector: 'app-accolades',
  templateUrl: './accolades.component.html'
})
export class AccoladesComponent {

  constructor() { }

}
