<div class="container px-6 mx-auto md:mb-16 mb-8 text-center md:pt-16 pt-8">
  <div class="text-center pb-16 pb-4">
    <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl">Products & Services</h1>
    <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">
      In the many years since we've been providing business services to our customers we have accumulated an
      impressive range
      of features that make it easy for you to get your cashflow and compliance issues sorted. Below are a number
      of services
      that we provide.
    </p>
  </div>
  <div class="flex flex-wrap text-grey-800 pb-16">
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/1.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Getting paid on time</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        It is no accident that some companies always get paid on time while others don’t. Our first service to
        our subscribers
        is to help them improve their cash flow; we have a very clever system that puts our subscribers on the
        top of their
        clients list to be paid.
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/3.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Documentation</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        We often get asked to collect a debt from someone who paid some of the account and promised to pay the
        balance later,
        but didn’t. We supply you with a library of letters, documents and forms to cover every contingency you
        may need to
        address, including deferred payments
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/3.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Terms of Trade</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        Ever wondered why some companies are always secured creditors in a Receivership and perhaps you are not?
        The answer is
        that unsecured creditors neglect to get permission from the client or simply don’t register; this is a
        major focus of
        the service we provide our subscribers.
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/2.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Health & Safety</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        We supply a personalised Health and Safety manual for offices, workshops, factories, shops, warehouses
        etc. which
        enables our subscribers and their staff to get started on the task of ensuring Health and Safety systems
        are put in
        place and monitored.
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/3.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">Credit Rating</h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        We designed a system twenty years ago that enables access to bad debtor information easily to stop them
        going from one
        business to another. In that time we have saved a whole range of business groups hundreds of thousands
        of dollars by
        eliminating credit risk.
      </p>
    </div>
    <div class="lg:w-1/3 w-full p-4">
      <div class="pb-8 flex items-center justify-center">
        <img src="/assets/set1/3.svg" alt="">
      </div>
      <h1 class="font-semibold text-grey-800 mb-2">A raft of other services </h1>
      <p class="text-grey-700 leading-wide mx-auto max-w-2xl">
        Running a business is supposed to be fun and profitable but sadly for many it is not. We provide many
        services to bring
        your business into the 21st century. It is reassuring to know that you are using the same system and
        processes that are
        used by hundreds of other business.
      </p>
    </div>
  </div>
</div>


<div class="container max-w-4xl mx-auto px-6 md:pb-16 pb-8 ">
  <div class="block text-center md:pb-16 pb-4">
    <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl mb-2">Who uses Debtorinfo?</h1>
    <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl mb-6">Debtorinfo has been built to
      suite many industries, where do you fit in?</p>
  </div>
  <div class="flex flex-wrap md:-mx-4 mb-6">
    <div class="md:w-1/3 sm:w-1/2 w-full px-3 pb-6">
      <div class="card-holder">
        <a class="img-card bg-grey-800">
          <div class="w-full">
            <span class="tag">Industry</span>
            <h1 class="text-xl font-semibold">Health Profession Industry</h1>
          </div>
          <!-- <div class="link">View Details</div> -->
        </a>
      </div>
    </div>
    <div class="md:w-1/3 sm:w-1/2 w-full px-3 pb-6">
      <div class="card-holder">
        <a class="img-card bg-grey-800">
          <div class="w-full">
            <span class="tag">Industry</span>
            <h1 class="text-xl font-semibold">Lawyers, Accountants and other advisors</h1>
          </div>
          <!-- <div class="link">View Details</div> -->
        </a>
      </div>
    </div>
    <div class="md:w-1/3 sm:w-1/2 w-full px-3 pb-6">
      <div class="card-holder">
        <a class="img-card bg-grey-800">
          <div class="w-full">
            <span class="tag">Industry</span>
            <h1 class="text-xl font-semibold">Building Subcontractors</h1>
          </div>
          <!-- <div class="link">View Details</div> -->
        </a>
      </div>
    </div>
    <div class="md:w-1/3 sm:w-1/2 w-full px-3 pb-6">
      <div class="card-holder">
        <a class="img-card bg-grey-800">
          <div class="w-full">
            <span class="tag">Industry</span>
            <h1 class="text-xl font-semibold">Electrical Industry</h1>
          </div>
          <!-- <div class="link">View Details</div> -->
        </a>
      </div>
    </div>
    <div class="md:w-1/3 sm:w-1/2 w-full px-3 pb-6">
      <div class="card-holder">
        <a class="img-card bg-grey-800">
          <div class="w-full">
            <span class="tag">Industry</span>
            <h1 class="text-xl font-semibold">Plumbing Industry</h1>
          </div>
          <!-- <div class="link">View Details</div> -->
        </a>
      </div>
    </div>
    <div class="md:w-1/3 sm:w-1/2 w-full px-3 pb-6">
      <div class="card-holder">
        <a class="img-card bg-grey-800">
          <div class="w-full">
            <span class="tag">Industry</span>
            <h1 class="text-xl font-semibold">Wholesalers, Merchants & Resellers</h1>
          </div>
          <!-- <div class="link">View Details</div> -->
        </a>
      </div>
    </div>
  </div>
</div>
