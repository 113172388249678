<div class="container px-6 mx-auto md:mb-16 mb-8 text-center md:pt-16 pt-8">
  <div class="text-center pb-16 pb-4">
    <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl">Why It Works</h1>
    <p class="md:text-xl text-2lg text-grey-600 leading-wide mx-auto max-w-2xl">
      Debtorinfo is a system that identifies people who don’t pay their accounts
    </p>
    <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto">
      Debtors who do not pay their accounts are listed on a register of bad debtors accessible to other companies
      and credit providers.
      While some debtors might not pay all their accounts, they do pay the ones that ensure they keep their A1
      Credit Rating.
      Debtorinfo motivates debtors to pay their accounts through good communication, and a meaningful incentive.
      Debtors who do not pay accounts when asked to by Debtorinfo are put on a Credit Stop on our database and
      other databases that banks and finance companies use.
      Debtorinfo has helped its subscribers collect millions of dollars without them paying loading fees or
      commission.
    </p>
  </div>
  <!-- QUOTES HERE -->
  <div class="container px-6 mx-auto md:pt-20">
    <div class="text-center pb-16 pb-4">
      <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl">What our subscribers say</h1>
    </div>
    <div class="border-t border-grey-300 md:pt-32 pt-16"></div>
    <div class="md:pb-16 pb-0 max-w-4xl mx-auto">
      <div class="flex justify-between flex-wrap items-center md:-mx-8 -mx-6">
        <div class="lg:flex-1 w-full px-8 lg:border-r border-r-0 border-grey-300 pb-10 md:block hidden">
          <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
            "Debtorinfo is incredibly successful in prompting payments, we really like it."
          </div>
          <div>
            <h1 class="font-semibold font-custom">A  Veterinary Centre</h1>
          </div>
        </div>
        <div class="lg:flex-1 w-full px-8">
          <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
            "We love the Debtorinfo system it works really well for us."
          </div>
          <div>
            <h1 class="font-semibold font-custom">A  Medical Centre</h1>
          </div>
        </div>
      </div>
      <div class="flex justify-between flex-wrap items-center md:-mx-8 -mx-6">
        <div class="lg:flex-1 w-full px-8 lg:border-r border-r-0 border-grey-300 pb-10 md:block hidden">
          <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
            "We have used it since it started, it’s brilliant."
          </div>
          <div>
            <h1 class="font-semibold font-custom">A  Motor Repair Workshop</h1>
          </div>
        </div>
        <div class="lg:flex-1 w-full px-8">
          <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
            "It is nice to have Debtorinfo there if we need it or need advice, it helps us in many ways."
          </div>
          <div>
            <h1 class="font-semibold font-custom">A Plumber</h1>
          </div>
        </div>
      </div>
      <div class="flex justify-between flex-wrap items-center md:-mx-8 -mx-6">
        <div class="lg:flex-1 w-full px-8 lg:border-r border-r-0 border-grey-300 pb-10 md:block hidden">
          <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
            "When we need to collect a debt, we use Debtorinfo and it works brilliantly."
          </div>
          <div>
            <h1 class="font-semibold font-custom">An  Importer</h1>
          </div>
        </div>
        <div class="lg:flex-1 w-full px-8">
          <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
            "We have only had to use Debtorinfo once and it worked."
          </div>
          <div>
            <h1 class="font-semibold font-custom">A Otago  producer</h1>
          </div>
        </div>
      </div>
      <div class="flex justify-between flex-wrap items-center md:-mx-8 -mx-6">
        <div class="lg:flex-1 w-full px-8 lg:border-r border-r-0 border-grey-300 pb-10 md:block hidden">
          <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
            "We use Debtorinfo and it works for us."
          </div>
          <div>
            <h1 class="font-semibold font-custom">A Building Contractor</h1>
          </div>
        </div>
        <div class="lg:flex-1 w-full px-8">
          <div class="text-base-blue text-lg md:text-xl leading-wide font-normal font-custom pb-8">
            "We subscribe to Debtorinfo and always get a positive result."
          </div>
          <div>
            <h1 class="font-semibold font-custom">A Plumber</h1>
          </div>
        </div>
      </div>
    </div>
    <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">
      While some debtors will delay payment if there are no repercussions, most debtors want to protect their
      credit rating and continue to get credit when they ask for it, so pay their account when they are advised
      that their credit rating is about to be down graded.
    </p>
  </div>
</div>
<div class="w-full text-sm">
  <div class="py-12">
    <div class="flex items-center mb-4">
      <img src="/assets/tick-dark.svg" alt="">
      <span class="pl-4 md:text-ss font-light text-grey-600 leading-wide ">Debtorinfo is a tool designed to
        improve your cash flow by encouraging your debtors to pay their accounts.
      </span>
    </div>
    <div class="flex items-center mb-4">
      <img src="/assets/tick-dark.svg" alt="">
      <span class="pl-4 md:text-ss font-light text-grey-600 leading-wide ">Communication is the best means to
        achieve a quick result and Debtorinfo provides the means to communicate with your debtors once a debt is
        one month old.</span>
    </div>
    <div class="flex items-center mb-4">
      <img src="/assets/tick-dark.svg" alt="">
      <span class="pl-4 md:text-ss font-light text-grey-600 leading-wide ">When an account is 2 or 4 weeks overdue
        you can send a friendly reminder to your debtor from your Debtorinfo web site that is shown as coming
        from you, thanking hem for their support and reminding them that the account remains unpaid.</span>
    </div>
    <div class="flex items-center mb-4">
      <img src="/assets/tick-dark.svg" alt="">
      <span class="pl-4 md:text-ss font-light text-grey-600 leading-wide ">You can send a second friendly reminder
        coming from you a couple of weeks later, or you can skip that and send a friendly reminder from
        Debtorinfo, this reminder is friendly and lets the debtor know that your overdue debts are managed by a
        third party.</span>
    </div>
    <div class="flex items-center mb-4">
      <img src="/assets/tick-dark.svg" alt="">
      <span class="pl-4 md:text-ss font-light text-grey-600 leading-wide ">Third part intervention can be
        successful when all else fails.</span>
    </div>
  </div>
</div>
