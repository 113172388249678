import { Component } from '@angular/core';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html'
})
export class ThanksComponent {

  constructor() { }

}
