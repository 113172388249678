<div class="container px-6 mx-auto">
    <div class="block text-center md:mb-16 mb-8 py-16 border-b border-grey-200">
        <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl">Contacted by Debtorinfo</h1>
        <!-- <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">Help manage cashflow since, 1996</p> -->
    </div>
</div>
<div class="container mx-auto max-w-4xl px-6 pb-16">
    <div class="pb-4">
        <div class="text-grey-700 font-semibold md:text-2xl text-xl">Have you been contacted by us?</div> ​
    </div>
    <p class="text-lg text-grey-700 leading-wide pb-4">
        We provide credit management solutions to our subscribers, and one of those solutions is the delivery and follow
        up of
        letters demanding payment for outstanding debts. These letters are a last stop measure and must be treated
        seriously as
        not tending to one of these letters will result in not only increasing late payment and debt recovery fees, but
        also
        your credit rating will be degraded and will impact your ability to receive credit with any one of our
        subscribers.
    </p>
    <p class="text-lg text-grey-700 leading-wide pb-4">
        If your account is not in dispute and you would like to pay it off the get started immediately by following the
        instructions in the letter. We encourage debtors to put an automatic payment in place and also realise that
        these
        regular payments need to be financially manageable to you, and acceptable to the company that you owe money to.
    </p>
    <p class="text-lg text-grey-700 leading-wide pb-4">
        Right now the very best thing to do is communicate - contact the company involved to seek a solution. Ignoring
        the letter will result in legal action.
    </p>
    <p class="text-lg text-grey-700 leading-wide pb-4">
        If you have received a letter from us and you believe this is in error then please contact us immediately and we
        can help.
    </p>
</div>