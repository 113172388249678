<div>
    <div class="bg-grey-900 pt-16">
        <div class="container mx-auto relative -mt-32">
            <div class="shadow-lg py-14 px-8 rounded flex flex-wrap justify-between items-center md:mx-0 mx-6"
                style="background: linear-gradient(326.42deg, #3BB1BD -38.44%, #72DDCD 114.9%);">
                <h1 class="text-white font-medium text-2xl md:mb-0 mb-4">Have you been contacted by Debtorinfo?</h1>
                <a routerLink="/contacted" class="btn md">Find out what to do</a>
            </div>
        </div>
    </div>
    <div class="bg-grey-900">
        <div class="container mx-auto lg:px-0 px-6 font-medium py-16">
            <div class="flex flex-wrap justify-between flex-1 pb-4">
                <div class="text-white">
                    <img class="logo" src="/assets/logo3.svg" alt="">
                </div>
                <div class="">
                    <a  routerLink="/contact" class="text-white px-2" href="">Send us a message</a>
                </div>
            </div>
            <div class="flex flex-wrap justify-between flex-1 text-sm">
                <div class="text-grey-500">
                    © {{ date.getFullYear() }} - Debtorinfo Limited. All rights reserved.
                </div>
                <div class="">
                    <a class="text-grey-500 px-2" routerLink="/contact">Contact</a>
                    <a class="text-grey-500 px-2" routerLink="/about">About Us</a>
                </div>
            </div>
        </div>
    </div>
</div>
