import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { ContactedComponent } from './contacted/contacted.component';
import { AboutComponent } from './about/about.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HealthComponent } from './professions/health/health.component';
import { CtaComponent } from './cta/cta.component';
import { EmailService } from './services/email.service';
import { ThanksComponent } from './thanks/thanks.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FaqComponent } from './faq/faq.component';
import { WhyItWorksComponent } from './why-it-works/why-it-works.component';
import { AccoladesComponent } from './accolades/accolades.component';
import { OurServicesComponent } from './our-services/our-services.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PricingComponent,
    ContactComponent,
    ContactedComponent,
    AboutComponent,
    NavbarComponent,
    FooterComponent,
    HealthComponent,
    CtaComponent,
    ThanksComponent,
    FaqComponent,
    WhyItWorksComponent,
    AccoladesComponent,
    OurServicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [EmailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
