import { Component } from '@angular/core';

@Component({
  selector: 'app-why-it-works',
  templateUrl: './why-it-works.component.html'
})
export class WhyItWorksComponent {

  constructor() { }

}
