<div class="container mx-auto relative lg:px-0 px-6">
    <div class="lg:pb-38 md:pb-24 pb-8 lg:pt-32">
        <div class="flex flex-wrap items-center md:flex-row flex-col-reverse">
            <div class="flex-1">
                <div class="lg:pr-8">
                    <div class="w-full lg:max-w-s mx-auto pb-0 lg:pb-12 px-6">
                        <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl pb-2">
                            Our Company Details
                        </h1>
                        <div class="pt-4">
                            <div>Debtorinfo Australia</div>
                            <div class="pb-4">georgeowen@xtra.co.nz</div>

                            <div>Pacific Regional Office</div>
                            <div>Debtorinfo Limited</div>
                            <div>​252 Cumberland Street</div>
                            <div>Dunedin</div>
                        </div>
                    </div>
                    <div class="container mx-auto px-6">
                        <h1 class="text-grey-700 font-semibold text-2xl mb-2">
                            You’re in good company
                        </h1>
                        <p class="text-lg font-light text-grey-600 leading-wide mx-auto max-w-2xl mb-6">
                            We provide our services to almost every type of profession, business and trade. No one has
                            been able to match our product, service and price for over 25 years.
                        </p>
                    </div>
                </div>
            </div>
            <div class="lg:w-1/2 md:px-8 w-full justify-center lg:pb-0 pb-16">
                <form [formGroup]="form">
                    <div class="bg-white shadow-lg rounded">
                        <div class="p-6 pb-0">
                            <h1 class="text-grey-700 font-semibold text-2xl pb-2">Get in Touch</h1>
                            <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">
                                Contact Debtorinfo
                            </p>
                        </div>
                        <div class="p-6">
                            <div class="pb-4">
                                <span class="pb-2 block text-grey-600 text-sm">Name</span>
                                <input formControlName="firstName" type="text"
                                    class="border border-grey-400 rounded w-full py-2 px-4">
                            </div>
                            <div class="pb-4">
                                <span class="pb-2 block text-grey-600 text-sm">Email</span>
                                <input formControlName="email" type="text"
                                    class="border border-grey-400 rounded w-full py-2 px-4">
                            </div>
                            <div class="pb-4">
                                <span class="pb-2 block text-grey-600 text-sm">Message</span>
                                <textarea formControlName="message" type="text"
                                    class="border border-grey-400 rounded w-full py-2 px-4"></textarea>
                            </div>
                            <button class="btn md w-full justify-center flex" disabled *ngIf="!form.valid && !sending">
                                Fill out form
                            </button>
                            <button class="btn primary md w-full justify-center flex" *ngIf="form.valid && !sending"
                                (click)="requestContact()">
                                Send
                            </button>
                            <button class="btn primary md w-full justify-center flex" *ngIf="sending">
                                Sending, please wait...
                            </button>
                            <p class="py-4 text-grey-600 text-base">
                                We will get you up and running within 60 minutes during office hours.
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>