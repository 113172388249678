<div class="container px-6 md:pb-12 mx-auto">
  <div class="block text-center md:mb-16 mb-8 md:py-16 py-8 border-b border-grey-200">
    <h1 class="text-grey-700 font-semibold md:text-3xl text-2xl">Fair Pricing</h1>
    <p class="md:text-xl text-lg text-grey-600 leading-wide mx-auto max-w-2xl">
      Based on current offerings on the market, access to the tools that we provide has been valued at over $3,000
      per year.
    </p>
  </div>
</div>
<div class="container pb-12 mx-auto">
  <div class="flex flex-wrap items-center">
    <div style="height: 40rem;" class="md:flex hidden md:w-38 w-full">
      <div class="max-w-md mx-auto pr-10">
        <div class="pb-6 text-2xl font-semibold">
          These are some of the Services available on the Debtorinfo web site
        </div>
        <!-- features -->
        <div class="pb-6 h-full text-base text-grey-600 text-left">
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Debt Management and Recovery Tools</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Integration with leading accounting systems</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Online - Quotes & Terms Acknowledgement</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Personalised Documentation, Forms and Letters specific to your Industry or
              Profession</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">AU Bad Debtors Register</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Debt Collection Solutions</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Contractors Terms of Trade</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">PPSR Registrations</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Construction Contracts Act</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Building Amendment Act</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Workers Lien</span>
          </div>
          <div class="flex items-center mb-4">
            <img src="/assets/tick-dark.svg" alt="">
            <span class="pl-4">Health and Safety Manual</span>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40rem;" class="md:w-30 w-full bg-grey-700 shadow-lg rounded-lg mt-5 md:mr-5">
      <div class="w-full text-white px-8 pt-8 pb-8 bg-grey-800 rounded-t-lg">
        <span class="tag">Pricing</span>
        <h1 class="text-2xl font-light">Platinum Service</h1>
        <div class=" ">
          <div class="text-white text-4xl font-semibold">$49.95 <span class="text-teal-200 text-2xl font-light">/
              month</span></div>
          <span class="text-teal-200 text-sm">excl. GST</span>
        </div>
      </div>
      <div class="px-8 py-12">
        <span class="font-medium text-teal-200 pb-4 block">Plan Comes with:</span>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Unlimited Collections</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Loading Fees</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Hidden Costs</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Surprises</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Unlimited Documentation <br>Access</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Unlimited access to the <br>AU Bad Debtors
            Register</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Discounts on Statutory <br>Demands and other
            services</span>
        </div>
      </div>
    </div>
    <div style="height: 40rem;" class="md:w-30 w-full bg-grey-700 shadow-lg rounded-lg mt-5">
      <div class="w-full text-white px-8 pt-8 pb-8 bg-grey-800 rounded-t-lg">
        <span class="tag">Pricing</span>
        <h1 class="text-2xl font-light">Gold Service</h1>
        <div class=" ">
          <div class="text-white text-4xl font-semibold">$20.00 <span class="text-teal-200 text-2xl font-light">/
              month</span></div>
          <span class="text-teal-200 text-sm">excl. GST</span>
        </div>
      </div>
      <div class="px-8 py-12">
        <span class="font-medium text-teal-200 pb-4 block">Plan Comes with:</span>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Unlimited Collections</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Loading Fees</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Hidden Costs</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">No Surprises</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Pay for access to <br>Documentation and Forms</span>
        </div>
        <div class="flex items-center mb-4">
          <img src="/assets/tick-dark.svg" alt="">
          <span class="pl-4 text-sm font-medium text-teal-100">Pay for access to the <br>AU Bad Debtors Register</span>
        </div>
      </div>
    </div>
  </div>
</div>
<h1 class="text-grey-700 font-bold md:text-3xl text-2xl text-center">Special Offer for this month</h1>
<div class="w-full"
  style="background: linear-gradient(270deg, #01ACC0 0%, #70D0DB 100%); animation: pulse 0.5s infinite;border-radius: 6px;">
  <div class="w-full px-5 py-3 text-2xl font-extrabold text-white text-center">If you are a member of a Trade Group or
    Business Assn we will sign you up to a Platinum Subscription for the Gold
    Subscription Price.</div>
</div>