import { Component } from '@angular/core';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html'
})
export class HealthComponent {

  constructor() { }

}
