import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ContactedComponent } from './contacted/contacted.component';
import { PricingComponent } from './pricing/pricing.component';
import { ThanksComponent } from './thanks/thanks.component';
import { FaqComponent } from './faq/faq.component';
import { WhyItWorksComponent } from './why-it-works/why-it-works.component';
import { AccoladesComponent } from './accolades/accolades.component';
import { AboutComponent } from './about/about.component';
import { OurServicesComponent } from './our-services/our-services.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'why-it-works',
    component: WhyItWorksComponent
  },
  {
    path: 'services',
    component: OurServicesComponent
  },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'accolades',
    component: AccoladesComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'contacted',
    component: ContactedComponent
  },
  {
    path: 'thanks',
    component: ThanksComponent
  },
  {
    path: 'about',
    component: AboutComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
