<div class="container mx-auto max-w-6xl px-6">
	<div class="pt-16 pb-16">
	</div>
</div>
<div class="container mx-auto max-w-2xl px-6">
	<div class="md:pb-16 pb-6">
		<h1 class="text-grey-900 font-bold text-4xl">Thank you</h1>
		<h2 class="text-grey-700 font-normal text-lg max-w-xl py-4">
			We will be in touch shortly, if you need to get in contact ugently please give us a call.
		</h2>
	</div>
</div>