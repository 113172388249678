import { Component } from '@angular/core';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html'
})
export class CtaComponent {

  constructor() { }

}
