import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  showNav: boolean = false;
  date: Date;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.date = new Date();
  }

  /**
   * Navigation from mobile nav.
   */
  navigate(destination: string) {
    this.showNav = false;
    this.router.navigate(['/', destination]);
  }

}
